import Script from 'next/script';
import { parseCookies } from 'nookies';
import { GA_TRACKING_ID } from '../../../scripts/gtag';

/**
 * Analytics component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @return JSX.Element
 */

export default function Analytics() {
  const cookies = parseCookies();
  const consent = cookies['cookies-consent'];

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}

                gtag('consent', 'default', {
                  'ad_storage': 'denied',
                  'analytics_storage': 'denied'
                });

                gtag('js', new Date());
                gtag('config', '${GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
        }}
      />

      {consent === 'true' && (
        <Script
          id="consent-update"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                  });
                `,
          }}
        />
      )}
    </>
  );
}
